import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Suggest } from "@blueprintjs/select";
import * as React from "react";
import { UseFormSetValue } from "react-hook-form";

import { useEditPortfolioStore } from "./PortfolioDialogs/EditPortfolioDialog/useEditPortfolioStore";

interface Benchmark {
  name: string;
  symbol: string;
}

const benchmarks: Benchmark[] = [
  { name: "S&P 500", symbol: "SPY" },
  { name: "Nasdaq", symbol: "QQQ" },
  { name: "U.S. Aggregate Bond", symbol: "AGG" },
];

const renderBenchmarkItem: ItemRenderer<Benchmark> = (benchmark, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      roleStructure="listoption"
      key={benchmark.symbol}
      label={benchmark.symbol}
      onClick={handleClick}
      text={highlightText(benchmark.name, query)}
    />
  );
};

const highlightText = (text: string, query: string) => {
  if (!query) return text;

  const escapedQuery = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

  const parts = text.split(new RegExp(`(${escapedQuery})`, "gi"));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? <strong key={index}>{part}</strong> : part,
      )}
    </>
  );
};

const filterBenchmark: ItemPredicate<Benchmark> = (query, benchmark) => {
  return benchmark.name.toLowerCase().includes(query.toLowerCase());
};

const IndexSelector = (props: { setValue: (value: string) => void }) => {
  const { setValue } = props;
  const { portfolio } = useEditPortfolioStore();
  const defaultSelectedItem = portfolio.benchmarkSymbol
    ? benchmarks.find((item) => item.symbol === portfolio.benchmarkSymbol)
    : undefined;
  const [selectedBenchmark, setSelectedBenchmark] = React.useState<Benchmark | undefined>(defaultSelectedItem);
  const handleItemSelect = (item: Benchmark) => {
    setSelectedBenchmark(item);
    setValue?.(item.symbol);
  };

  return (
    <Suggest<Benchmark>
      defaultSelectedItem={selectedBenchmark}
      items={benchmarks}
      itemRenderer={renderBenchmarkItem}
      onItemSelect={handleItemSelect}
      itemPredicate={filterBenchmark}
      inputValueRenderer={(item) => item.name}
      noResults={<MenuItem disabled={true} text="No results." />}
      inputProps={{ placeholder: "Select a benchmark" }}
      popoverProps={{ matchTargetWidth: true, minimal: true }}
    />
  );
};

export default IndexSelector;
