import { Configuration, DefaultApi } from "@tradeblotter/tradeblotter-api";

import { config } from "../config";
import { getOutsetaToken } from "../utils";

class APIClient {
  public api: DefaultApi;

  constructor() {
    this.api = new DefaultApi(
      new Configuration({
        basePath: config.API_BASE_URL,
        middleware: [
          {
            pre: async (context) => {
              const authHeader = {
                Authorization: `Bearer ${getOutsetaToken()}`,
              };
              context.init.headers = {
                ...context.init.headers,
                ...authHeader,
              };
            },
            post: async (context) => {
              const rawResponse = await context.response.text();
              const parsedResponse = JSON.parse(rawResponse.replace(/\bNaN\b/g, "null"));

              // Handle null arrays
              if (parsedResponse?.results?.summary?.duplicated === null) parsedResponse.results.summary.duplicated = [];
              if (parsedResponse?.results?.summary?.failed === null) parsedResponse.results.summary.failed = [];
              if (parsedResponse?.results?.summary?.successful === null) parsedResponse.results.summary.successful = [];

              // Throw a custom error for non-2xx status codes
              if (context.response.status >= 400) {
                throw new ApiResponseError(
                  parsedResponse.message || "API Error",
                  parsedResponse,
                  context.response.status,
                );
              }

              // Return successful response
              return new Response(JSON.stringify(parsedResponse), {
                headers: context.response.headers,
                status: context.response.status,
                statusText: context.response.statusText,
              });
            },
          },
        ],
      }),
    );
  }
}

export class ApiResponseError extends Error {
  response: any;
  status: number;

  constructor(message: string, response: any, status: number) {
    super(message);
    this.name = "ApiResponseError";
    this.response = response;
    this.status = status;
  }
}

export const apiClient = new APIClient();
